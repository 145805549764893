import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { Parallax, Background } from 'react-parallax';

import {
  Wrapper,
  Content,
  WrapperImage,
  ContentImage,
  ContentCenter,
} from './Hero.styles';
import HTMLReactParser from 'html-react-parser';

const Hero = (props) => {
  //PROPS
  const {
    data: { image, text, title, subtitle },
    center = false,
  } = props;
  //PROPS

  if (image) {
    return (
      <WrapperImage>
        <Parallax strength={300}>
          <Background className="custom-bg">
            <GatsbyImage
              image={image?.localFile?.childImageSharp?.gatsbyImageData}
              alt={image?.altText}
            />
          </Background>
          <ContentImage>
            <div className="content">
              <h1>Facilisis leo vel fringilla est ullamcorper eget nulla</h1>
              <p>
                Mauris commodo quis imperdiet massa tincidunt nunc pulvinar
                sapien et ligula ullamcorper malesuada proin libero nunc
                consequat interdum varius sit amet mattis vulputate enim
              </p>
              <span></span>
            </div>
          </ContentImage>
        </Parallax>
      </WrapperImage>
    );
  } else if (center) {
    return (
      <Wrapper>
        <ContentCenter>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
          {text && HTMLReactParser(text)}
        </ContentCenter>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <Content>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
          {text && HTMLReactParser(text)}
        </Content>
      </Wrapper>
    );
  }
};

export default Hero;
