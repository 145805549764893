import styled from 'styled-components';

import { neutral, brand } from 'theme/colors';
import { breakpoints, gridSize, toRem } from 'utils/mixins';
import { container, space, fontSize } from 'theme/sizes';
import { h1, h4 } from 'theme/typography';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${container.xsmall};
  padding: ${space.l} ${space.s};
  margin: 0 auto;

  @media ${breakpoints.bigDesktop} {
    max-width: ${container.default};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h1 {
    ${h4}
    line-height: 1;
    margin-bottom: ${space.xs};
  }

  h2 {
    position: relative;
    display: inline;
    line-height: 1;
    margin-bottom: ${space.m};
    padding-bottom: ${space.xs};

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: ${space.l};
      height: ${toRem(4)};
      border-radius: ${space.xxs};
      background-color: ${brand.main};
    }
  }

  p {
    margin: 0;
  }

  @media ${breakpoints.laptop} {
    max-width: ${gridSize(6)};
  }

  @media ${breakpoints.bigDesktop} {
    max-width: ${gridSize(4)};
  }
`;

export const ContentCenter = styled(Content)`
  margin: 0 auto;
  align-items: flex-start;
  text-align: left;

  @media ${breakpoints.laptop} {
    align-items: center;
    text-align: center;

    h2 {
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;

export const WrapperImage = styled(Wrapper)`
  margin: none;
  padding: 0;
  max-width: unset;

  .react-parallax {
    width: 100%;
    height: 100vh;
    .gatsby-image-wrapper {
      width: 100vw;
      height: 100vh;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 1) 100%
      );

      * {
        object-fit: cover;
      }

      picture {
        opacity: 0.6;
      }
    }
  }
`;

export const ContentImage = styled(Content)`
  margin: 0 auto;
  max-width: ${container.default};
  width: calc(100% - 140px);
  padding: 0 20px;

  .content {
    margin-top: 50vh;
    height: 50vh;
    width: ${gridSize(4)};
  }

  h1 {
    color: ${neutral.neu01};
    ${h1}
  }

  p {
    color: ${neutral.neu01};
  }

  span {
    position: absolute;
    display: block;
    background-color: white;
    width: 1px;
    height: 10vh;
    left: calc(50%);
    bottom: 0;
  }
`;
